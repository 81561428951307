
:root {
  --heading-4-font-family: #{$basis-grotesque-pro-font-stack};
  --heading-4-font-size: 24px;
  --heading-4-font-style: normal;
  --heading-4-font-variant: normal;
  --heading-4-font-weight: bold;
  --heading-4-letter-spacing: -.001em;
  --heading-4-line-height: 1.2em;
  --heading-4-margin-bottom: .5rem;
  --heading-4-margin-top: 2rem;

  @include portrait {
    --heading-4-font-size: 28px;
  }

  @include landscape {
    --heading-4-font-size: 32px;
  }
}
