.language-switch {
  display: flex;
  list-style: none;
  padding: 0;

  li:not(:last-child) {
    margin-right: 10px;
    position: relative;

    &::after {
      content: '|';
      position: absolute;
      right: -12px;
    }
  }

  li:not(:first-child) {
    margin-left: 10px;
  }
}

.language-switch--menu-overlay {
  @include typography(link-big-bold);
}
