
.figure {
  contain: content;
  position: relative;

  .picture,
  .video {
    height: 100%;
    width: 100%;
  }
}

.figure__caption {
  @include typography(caption, true, false);

  .article-photo-carousel & {
    // Make room for the swiper navigation.
    margin-left: 100px;

    // Help the swiper buttons get some space.
    min-height: 40px;
  }
}
