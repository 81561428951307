
.link {
  color: inherit;
  contain: content;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  transition: color $easing-change;
  vertical-align: bottom;
}

.link__span {
  display: inline-block;
  vertical-align: baseline;
}

.link__icon {
  display: inline-block;
  height: 1em;
  padding: .1em;
  position: relative;
  vertical-align: middle;
  width: 1em;

  > svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.link--default {
  @include typography(paragraph);

  &:hover,
  &:focus {
    .link__span {
      text-decoration: underline;
    }
  }
}

.link--big {  // floabn
  @include style-anchor-bordered;
  @include typography(link-big);
}

.link--big-bold {  // floabn
  @include style-anchor-bordered;
  @include typography(link-big-bold);
}

.link--big-bold-inverted {  // floabn
  @include style-anchor-bordered($inverted:true);
  @include typography(link-big-bold);
}

.link--big-closer {
  @include style-anchor-bordered;
  @include typography(link-big);

  .link__span {
    margin-right: 10px;
  }

  .link__icon {
    height: .875em;
    width: .875em;
  }
}

.link--big-inverted {  // floabn
  @include style-anchor-bordered($inverted:true);
  @include typography(link-big);
}

.link--main-inverted {
  @include style-anchor-bordered($inverted:true);
  @include typography(link-main);
}

.link--menu-overlay-item {
  @include style-anchor-bordered($inverted:true);
  @include typography(link-big-bold);
}

.link--menu-overlay-item-active {
  @include typography(link-big-bold);

  border-bottom: 1px solid currentColor;
}

.link--no-style-active {
  border-bottom: 1px solid currentColor;
}

.link--tiny {
  @include style-anchor-bordered;
  @include typography(link-tiny);

  align-items: center;
  display: flex;

  .link__icon {
    margin-right: 5px;
  }
}
