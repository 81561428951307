
// Grid variables
//
// You can add or remove breakpoints at will,
// but be sure to add/remove/modify the relevant mixins as well!

:root {
  // Breakpoints
  --breakpoint-mobile: #{$mobile};
  --breakpoint-portrait: #{$portrait};
  --breakpoint-landscape: #{$landscape};
  --breakpoint-notebook: #{$notebook};
  --breakpoint-desktop: #{$desktop};

  // Breakpoint reference (for javascript)
  --breakpoint: var(--breakpoint-mobile);

  @include portrait {
    --breakpoint: var(--breakpoint-portrait);
  }

  @include landscape {
    --breakpoint: var(--breakpoint-landcape);
  }

  @include notebook {
    --breakpoint: var(--breakpoint-notebook);
  }

  @include desktop {
    --breakpoint: var(--breakpoint-desktop);
  }

  // Document constraints
  --document-min-width: #{$min-width};
  --document-max-width: #{$max-width};

  // Grid settings
  --grid-columns: 12;
  --grid-gutter: 20px;
  --grid-spacing: 20px;
  --grid-margin: -10px;
  --grid-max-width: 100%;

  @include portrait {
    --grid-gutter: 20px;
    --grid-spacing: 20px;
  }

  @include landscape {
    --grid-gutter: 20px;
    --grid-spacing: 20px;
  }

  @include notebook {
    --grid-gutter: 20px;
    --grid-spacing: 20px;
    --grid-max-width: 1440px;
  }

  @include desktop {
    --grid-gutter: 20px;
    --grid-spacing: 20px;
  }

  // Component settings
  --margin: 40px;
  --beat: 10px;

  @include portrait {
    --margin: 40px;
  }

  @include landscape {
    --margin: 60px;
  }

  @include notebook {
    --margin: 60px;
  }

  @include desktop {
    --margin: 60px;
  }
}

@mixin grid-container($columns: 'repeat(var(--grid-columns), [column] minmax(0, 1fr))') {
  display: grid;
  grid-gap: var(--grid-spacing) var(--grid-gutter);
  grid-template-columns: unquote($columns);
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--grid-max-width) + (var(--grid-gutter) * 2) + (var(--grid-margin) * 2));
  padding-left: calc(var(--grid-gutter) + var(--grid-margin));
  padding-right: calc(var(--grid-gutter) + var(--grid-margin));
}

@mixin subgrid-container($columns: auto) {
  display: grid;
  grid-gap: var(--grid-spacing) var(--grid-gutter);
  grid-template-columns: $columns;
}
