
:root {
  --caption-font-family: #{$basis-grotesque-pro-font-stack};
  --caption-font-size: 18px;
  --caption-font-style: normal;
  --caption-font-variant: normal;
  --caption-font-weight: regular;
  --caption-letter-spacing: -.03em;
  --caption-line-height: 24px;
  --caption-margin-bottom: 0;
  --caption-margin-top: 8px;

  @include portrait {
    //
  }

  @include landscape {
    //
  }
}
