
:root {
  --heading-2-font-family: #{$basis-grotesque-pro-font-stack};
  --heading-2-font-size: 32px;
  --heading-2-font-style: normal;
  --heading-2-font-variant: normal;
  --heading-2-font-weight: bold;
  --heading-2-letter-spacing: -.03em;
  --heading-2-line-height: 1.15em;
  --heading-2-margin-bottom: .5rem;
  --heading-2-margin-top: 2rem;

  @include portrait {
    --heading-2-font-size: 46px;
  }

  @include landscape {
    --heading-2-font-size: 64px;
  }
}
