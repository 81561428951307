
@mixin hover($target:&, $child-selector:'') {
  html.no-touch #{$target}:hover #{$child-selector} {
    @content;
  }

  html.touch #{$target}:active #{$child-selector} {
    @content;
  }
}

@mixin focus($target:&, $child-selector:'') {
  html.no-touch #{$target}:active #{$child-selector},
  html.no-touch #{$target}:focus #{$child-selector} {
    @content;
  }
}

@mixin hover-and-focus($target:&, $child-selector:'') {
  @include hover($target, $child-selector) {
    @content;
  }

  @include focus($target, $child-selector) {
    @content;
  }
}
