
:root {
  --dig-deeper-font-family: #{$basis-grotesque-pro-font-stack};
  --dig-deeper-font-size: 20px;
  --dig-deeper-font-style: normal;
  --dig-deeper-font-variant: normal;
  --dig-deeper-font-weight: bold;
  --dig-deeper-letter-spacing: -.56px;
  --dig-deeper-line-height: 26px;
  --dig-deeper-margin-bottom: 0;
  --dig-deeper-margin-top: 0;

  @include landscape {
    --dig-deeper-font-size: 32px;
    --dig-deeper-letter-spacing: -.9px;
    --dig-deeper-line-height: 41px;
  }
}
