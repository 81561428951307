$background: transparent;
$foreground: var(--color-white);

.progress-bar {
  -webkit-appearance: none;
  appearance: none;
  background-color: $background;
  border: none; // For Firefox and Opera
  color: $foreground; // For IE
  display: block;
  height: 6px;

  &::-moz-progress-bar {
    background-color: $foreground;
  }

  &::-webkit-progress-bar {
    background-color: $background;
  }

  &::-webkit-progress-value {
    background-color: $foreground;
  }
}
