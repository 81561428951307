
.menu-overlay {
  background-color: rgba($color-black, .5);
  bottom: 0;
  color: var(--color-black);
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-menu-overlay;

  html.has-js & {
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity $easing-change;
  }
}

.menu-overlay--visible {
  html.has-js & {
    opacity: 1;
    pointer-events: auto;
  }

  html:not(.has-js) & {
    display: flex;
  }
}

.menu-overlay__closer {
  display: block;
  margin-bottom: 10vh;
}

.menu-overlay__main {
  align-items: flex-end;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 25px 15px;
  position: relative;

  @include mobile-only {
    width: auto;
  }

  @include portrait {
    padding: 40px;
    width: 520px;
  }

  html.has-js & {
    @include mobile {
      transition: opacity $easing-change;
    }

    @include portrait {
      transition: transform $easing-change;
    }
  }

  html.has-js .menu-overlay:not(.menu-overlay--visible) & {
    @include mobile-only {
      opacity: 0;
    }

    @include portrait {
      transform: translateX(100%);
    }
  }
}

.menu-overlay__main-item {
  display: block;
  margin-bottom: 6vh;
  text-align: right;
}

.menu-overlay__main-items {
  list-style: none;
  margin-bottom: 6vh;
  text-align: right;
}

.menu-overlay__navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.menu-overlay__sec-items {
  list-style: none;
  margin-bottom: 10vh;
  text-align: right;
}
