
:root {
  --paragraph-bold-font-family: #{$basis-grotesque-pro-font-stack};
  --paragraph-bold-font-size: 16px;
  --paragraph-bold-font-style: normal;
  --paragraph-bold-font-variant: normal;
  --paragraph-bold-font-weight: bold;
  --paragraph-bold-letter-spacing: -.03em;
  --paragraph-bold-line-height: 1.5em;
  --paragraph-bold-margin-bottom: .5em;
  --paragraph-bold-margin-top: 1em;

  @include portrait {
    --paragraph-bold-font-size: 17px;
    --paragraph-bold-line-height: 1.5em;
  }

  @include landscape {
    --paragraph-bold-font-size: 18px;
    --paragraph-bold-line-height: 1.5em;
  }
}
