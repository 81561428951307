
.marquee-bar {
  align-items: center;
  background: var(--color-white);
  color: var(--color-black);
  display: flex;
  height: 40px;
  overflow: hidden;

  @include mobile-only {
    display: none;
  }
}

.marquee-bar__prefix {
  @include typography(paragraph);

  margin-right: 15px;
}

.marquee-bar__text {
  @include typography(paragraph-bold);
}
