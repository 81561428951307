
.intro-overlay {
  background-color: $color-white;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  color: var(--color-black);
  display: block;
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 3s ease;
  z-index: $z-index-intro-overlay;
}

.intro-overlay--hide {
  display: none;
}

.intro-overlay--visible {
  opacity: 1;
  pointer-events: auto;
}

.intro-overlay__close-intro {
  @include portrait {
    display: none;
  }
}

.intro-overlay__content {
  height: 100%;
  position: relative;
}

.intro-overlay__header {
  left: 0;
  padding: 0 25px;
  position: absolute;
  top: 42%;
  transform: translateY(-42%);
  transition: opacity 800ms ease;
  z-index: 5;

  @include mobile-only {
    width: 100%;
  }

  @include portrait {
    left: 50%;
    top: 50%;
    transform: translate(-70%, -50%);
  }

  .intro-overlay--fade-header & {
    opacity: 0;
    pointer-events: none;
  }
}

.intro-overlay__intro-image {
  height: 100%;

  @include portrait {
    display: none;
  }
}

.intro-overlay__intro-video.video {
  pointer-events: none;

  @include mobile-only {
    display: none;
  }

  video {
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
  }

  .video__hit-target {
    display: none;
  }
}

.intro-overlay__label {
  @include typography(heading-label);

  margin-bottom: 15px;
  margin-top: 15px;

  @include portrait {
    margin-bottom: 10px;
  }
}

.intro-overlay__language-switch {
  .intro-overlay--visible & {
    pointer-events: all;
  }

  .intro-overlay--state-movie & {
    display: none;
  }

  @include portrait {
    // align with menu button
    position: relative;
    top: -5px;
  }
}

.intro-overlay__main-video.video {
  .video__hit-target {
    display: none;
  }

  video {
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
  }
}

.intro-overlay__marquee-bar {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.intro-overlay__progress-bar {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.intro-overlay__skip-movie {
  .intro-overlay--visible & {
    pointer-events: all;
  }

  .intro-overlay:not(.intro-overlay--state-movie) & {
    display: none;
  }

  @include portrait {
    // align with menu button
    position: relative;
    top: -2px;
  }
}

.intro-overlay__sound-button {
  .intro-overlay:not(.intro-overlay--state-movie) & {
    @include mobile-only {
      display: none;
    }
  }
}

.intro-overlay__start-movie {
  @include mobile-only {
    display: none;
  }
}

.intro-overlay__state-intro {
  height: 100%;
  position: relative;

  .intro-overlay--state-movie & {
    display: none;
  }
}

.intro-overlay__state-movie {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .intro-overlay:not(.intro-overlay--state-movie) & {
    display: none;
  }
}

.intro-overlay__title {
  @include typography(heading-intro);

  color: var(--color-black);
  margin-bottom: 20px;
  margin-top: 15px;

  @include portrait {
    margin-bottom: 40px;
    margin-top: 10px;
  }

  @include landscape {
    margin-left: -2px;
  }
}

.intro-overlay__watch-introduction {
  display: block;
  margin-top: 20px;

  @include portrait {
    display: none;
  }
}
