
:root {
  --heading-6-font-family: #{$basis-grotesque-pro-font-stack};
  --heading-6-font-size: 16px;
  --heading-6-font-style: normal;
  --heading-6-font-variant: normal;
  --heading-6-font-weight: bold;
  --heading-6-letter-spacing: 0;
  --heading-6-line-height: 1.2em;
  --heading-6-margin-bottom: .5rem;
  --heading-6-margin-top: 2rem;

  @include portrait {
    --heading-6-font-size: 17px;
  }

  @include landscape {
    --heading-6-font-size: 18px;
  }
}
