
:root {
  --link-tiny-font-family: #{$basis-grotesque-pro-font-stack};
  --link-tiny-font-size: 12px;
  --link-tiny-font-style: normal;
  --link-tiny-font-variant: normal;
  --link-tiny-font-weight: medium;
  --link-tiny-letter-spacing: -.03em;
  --link-tiny-line-height: 12px;
  --link-tiny-margin-bottom: 0;
  --link-tiny-margin-top: 0;

  @include portrait {
    --link-tiny-font-size: 12px;
    --link-tiny-line-height: 12px;
  }

  @include landscape {
    --link-tiny-font-size: 12px;
    --link-tiny-line-height: 12px;
  }
}
