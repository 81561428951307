
.icon-button {
  @include typography(button);

  align-items: center;
  background-color: var(--color-white);
  border: 0;
  color: var(--color-black);
  contain: content;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: 40px;

  &:hover,
  &:focus {
    background-color: var(--color-black);
    color: var(--color-white);
  }
}

.icon-button__icon {
  display: block;
  height: var(--button-line-height);
  width: var(--button-line-height);

  > svg {
    display: block;
    fill: currentColor;
    height: 100%;
    width: 100%;
  }

  .icon-button--video-control & {
    height: 32px;
    width: 32px;
  }
}

.icon-button__timer {
  .icon-button--timed & {
    height: 60px;
    left: -8px;
    position: absolute;
    top: -8px;
    width: 60px;
  }
}

.icon-button--back-button {
  padding: 20px;

  @include mobile-only {
    height: 60px;
    width: 60px;
  }

  @include portrait {
    height: 75px;
    width: 75px;
  }
}

.icon-button--clean {
  background-color: transparent;
  color: currentColor;

  &:hover,
  &:focus {
    background-color: transparent;
    color: var(--color-black);
  }
}

.icon-button--dark-square {
  background: var(--color-black);
  border-radius: 0;
  color: var(--color-white);
}

.icon-button--default {
  border-radius: 20px;
}

.icon-button--timed {
  border-radius: 50%;
  contain: initial;
  height: 44px;
  overflow: visible;
  width: 44px;
}

.icon-button--video-control {
  border-radius: 50%;
  padding: 0;

  &:hover,
  &:focus {
    background: rgba($color-black, .6);
  }
}
