
// Layouts

:root {
  --layout-content: column 2 / span 10;
  --layout-aside: column 2 / span 10;
  --layout-photo: column 1 / span 12;

  @include portrait {
    --layout-content: column 3 / span 8;
    --layout-aside: column 3 / span 8;
    --layout-photo: column 3 / span 10;
  }

  @include landscape {
    --layout-content: column 4 / span 8;
    --layout-aside: column 1 / span 3;
    --layout-photo: column 3 / span 10;
  }

  @include notebook {
    --layout-content: column 4 / span 6;
    --layout-aside: column 2 / span 2;
    --layout-photo: column 3 / span 10;
  }
}
