
:root {
  --paragraph-font-family: #{$basis-grotesque-pro-font-stack};
  --paragraph-font-size: 16px;
  --paragraph-font-style: normal;
  --paragraph-font-variant: normal;
  --paragraph-font-weight: regular;
  --paragraph-letter-spacing: -.03em;
  --paragraph-line-height: 1.5em;
  --paragraph-margin-bottom: .5em;
  --paragraph-margin-top: 1em;

  @include portrait {
    --paragraph-font-size: 17px;
    --paragraph-line-height: 1.5em;
  }

  @include landscape {
    --paragraph-font-size: 18px;
    --paragraph-line-height: 1.5em;
  }
}
