
.video-chapter {
  position: absolute;
}

.video-chapter__content {
  position: relative;
}

.video-chapter__hit-target {
  background: var(--color-white);
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 12px;
  position: relative;
  width: 12px;
  z-index: 10;
}

.video-chapter__picture {
  background: transparent;
  border-radius: 50%;
  height: 75px;
  min-width: 75px;
}

.video-chapter__popup {
  align-items: center;
  bottom: 150%;
  display: flex;
  flex-direction: row;
  left: 0;
  margin-bottom: 15px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transform: translateX(-34px);
  transition: opacity .2s ease;
  width: auto;

  .video-chapter--show-popup & {
    opacity: 1;
  }
}

.video-chapter__title {
  color: var(--color-white);
  margin-left: 10px;
  order: 2;
}
