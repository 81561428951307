
.article-video-embed {
  contain: content;
  margin: var(--margin) 0;
  position: relative;

  .article-video-embed__grid {
    @include grid-container;
  }

  .article-video-embed__wrapper {
    grid-column: var(--layout-photo);
  }
}
