
.video-embed {
  overflow-x: hidden;
  position: relative;
}

.video-embed__button {
  color: var(--color-white);
  display: none;
  font-size: 2rem;
  height: 96px;
  left: 50%;
  line-height: 1em;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.video-embed__button-span {
  display: none;
}

.video-embed__container {
  background-color: var(--color-black);
  color: var(--color-white);
  overflow: hidden;
  position: relative;
  width: 100%;

  .picture {
    height: auto;
    opacity: 1;
    width: 100%;
  }
}

.video-embed__hit-target {
  background-color: rgba(var(--color-black), .25);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  text-decoration: none;
  top: 0;
  width: 100%;
  z-index: 100;
}

.video-embed__iframe,
.video-embed iframe {
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity $easing-change-slower;
  width: 100%;
}

.video-embed__iframe--hidden {
  opacity: 0;
  pointer-events: none;
}

.video-embed__play-button {
  color: var(--color-white);
  display: block;
  height: 60px;
  left: 50%;
  opacity: .5;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 60px;

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  .video-embed--autoplay & {
    @include mobile {
      display: none;
    }
  }
}
