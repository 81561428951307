
.richtext {
  contain: content;
  position: relative;
}

.richtext--regular {
  h1 {
    @include typography(heading-1, true, true);
  }

  h2 {
    @include typography(heading-2, true, true);
  }

  h3 {
    @include typography(heading-3, true, true);
  }

  h4 {
    @include typography(heading-4, true, true);
  }

  h5 {
    @include typography(heading-5, true, true);
  }

  h6 {
    @include typography(heading-6, true, true);
  }

  p {
    @include typography(paragraph, true, true);

    strong,
    b {
      font-weight: bold;
    }

    em,
    i {
      font-style: italic;
    }
  }
}

.richtext--regular,
.richtext--simple {
  p,
  ul,
  ol {
    &:not(:first-child) {
      margin-top: var(--paragraph-margin-top);
    }

    &:not(:last-child) {
      margin-bottom: var(--paragraph-margin-bottom);
    }
  }

  li,
  li > ul,
  li > ol {
    &:not(:first-child) {
      margin-top: calc(var(--paragraph-margin-top) / 2);
    }

    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) / 2);
    }
  }

  p,
  li {
    > a {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;

        &:hover {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }

  ul {
    @include style-list-base;
    @include style-list-unordered;
  }

  ol {
    @include style-list-base;
    @include style-list-ordered;
  }
}

.richtext--introduction {
  p {
    @include typography(introduction, true, true);

    > a {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;

        &:hover {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}
