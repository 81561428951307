
.card {
  --card-padding: 15px;

  color: $color-black;
  overflow: hidden;
  position: relative;
  transform: translateZ(0); // fixes border radius with overflow hidden not working in safari
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  &::before {
    content: '';
    padding-bottom: 58%;// <- waarom is dit?
  }

  &:hover,
  &:focus {
    .card__title span {
      background-size: 100% 1px;
    }
  }

  .card__image {
    position: relative;
  }

  .card__label {
    background: $color-black;
    bottom: 0;
    color: $color-white;
    left: 0;
    padding: 5px 10px;
    position: absolute;
  }

  .card__link {
    color: inherit;
    text-decoration: underline;
  }

  .card__content {
    padding: 20px;
    position: relative;
    z-index: 99;
  }

  .card__title {
    @include typography(heading-4);

    word-break: break-word;

    span {
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      transition: background-size .2s linear;
      vertical-align: baseline;
    }
  }
}
