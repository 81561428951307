.menu-bar {
  color: var(--color-black);
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $z-index-menu-bar;

  &::before {
    background: rgba(255, 255, 255, 0);
    background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, .2) 80%);
    background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, .2) 80%);
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, .2) 80%);
    content: '';
    display: block;
    height: 200px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .menu-bar--light {
    color: var(--color-white);
  }
}

.menu-bar__back-button {
  pointer-events: all;
  position: relative;
  // align to top
  top: -15px;

  @include portrait {
    top: -30px;
  }
}

.menu-bar__buttons-container {
  align-items: center;
  display: flex;
  margin-left: 15px;
  margin-right: -25px + 15px;

  @include mobile-only {
    height: 55px;
  }

  @include portrait {
    align-items: flex-start;
    margin-left: 40px;
    margin-right: -20px + 40px;
  }

  > * {
    margin-left: 25px;
    margin-right: 25px;

    @include portrait {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.menu-bar__close-link {
  pointer-events: all;

  .menu-bar:not(.menu-bar--show-closer) & {
    display: none;
  }

  @include portrait {
    // align with menu button
    position: relative;
    top: -2px;
  }
}

.menu-bar__items {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 15px;
  width: 100%;

  @include portrait {
    padding-top: 30px;
  }
}

.menu-bar__logo {
  pointer-events: all;

  @include mobile-only {
    height: 90px;
    margin-left: 25px;
    margin-right: 15px;
    margin-top: 12px;
    width: 60px;
  }

  @include portrait {
    margin-left: 40px + 10px;
    margin-right: 40px;
  }
}

.menu-bar__menu-link {
  pointer-events: all;

  @include portrait {
    // align with menu button
    position: relative;
    top: -4px;
  }

  .menu-bar--show-closer & {
    display: none;
  }
}

.menu-bar__sound-button {
  height: 21px;
  pointer-events: all;
  position: relative;
  // align with menu button
  top: -3px;
  width: 21px;

  @include portrait {
    height: 25px;
    // align with menu button
    top: -6px;
    width: 25px;
  }

  .menu-bar--show-closer & {
    display: none;
  }
}
