
.hero-header {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  .figure,
  .video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video__video {
    height: 100%;
    object-fit: contain;

    &::-webkit-media-controls-panel {
      padding-right: 270px;
    }
  }
}
