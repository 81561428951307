
.footer-bar {
  background-color: $color-white;
  color: $color-black;
  padding: 60px 0;
  position: relative;

  @include notebook {
    padding: 100px 0 30px;
  }

  .footer-bar__column-title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
  }

  .footer-bar__grid {
    @include grid-container;

    max-width: 100%;
  }

  .logo {
    left: -20px;
    position: relative;
    transform: scale(1.6);
    transform-origin: bottom left;
  }

  .footer-bar__column {
    grid-column: column 1 / span 12;
    margin-bottom: 10px;
    padding: 0 20px;

    @include landscape {
      display: flex;
      flex-direction: column;
      grid-column: auto / span 4;
      justify-content: flex-end;
      margin-bottom: 0;
    }

    &:nth-child(1) {
      @include landscape {
        grid-column: auto / span 2;
      }
    }

    &:nth-child(2) {
      margin-top: -15px;

      @include landscape {
        grid-column: auto / span 4;
        margin-top: 0;
      }
    }

    &:nth-child(3) {
      order: 4;
      padding: 0 20px;

      @include landscape {
        grid-column: auto / span 6;
        order: initial;
      }
    }

    //&:nth-child(4) {
    //  margin-bottom: 30px;
    //
    //  @include landscape {
    //    grid-column: column 1 / span 12;
    //  }
    //}

    .link-list--vertical {
      column-count: 2;

      .link-list__links {
        display: block;

        .link {
          display: block;
        }

        .link__span {
          font-size: 20px;

          @include landscape {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }

    .link-list--horizontal .link:not(:last-child)::after {
      content: '|';
      margin-left: 20px;
      margin-right: 20px;
    }

    .link-list--horizontal .link {
      margin-right: 0;
    }
  }

  .footer-bar__bottom-links:not(:first-child) {
    margin-top: 19px;
  }

  .footer-logo {
    color: $color-black;
    height: auto;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }

  .icon-button {
    background-color: transparent;
    color: $color-black;
  }

  .icon-button:hover,
  .icon-button:focus {
    background-color: transparent;
    color: $color-black;
  }
}
