
.chaptered-video {
  height: 100vh;
  position: relative;
  z-index: 1;

  // TODO: make it a variant-cover?
  .video__container {
    padding-bottom: 0;
  }

  .video__hit-target {
    @include portrait {
      display: none;
    }
  }

  .video__video {
    height: 100vh;
    object-fit: cover;
    object-position: bottom center;
    position: static;
  }
}

.chaptered-video__chapter {
  list-style: none;
  position: absolute;
  top: 0;
}

.chaptered-video__chapters {
  height: 12px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 7px;
  transform: translateY(-4px); // yes why... (hint: trial and error)
  width: 100%;

  .video-chapter {
    pointer-events: all;
  }
}

.chaptered-video__fixed {
  //left: 0;
  //position: fixed;
  //top: 0;
  //width: 100%;
}

.chaptered-video__inner {
  height: 100vh;
  position: relative;
}

.chaptered-video__navigation {
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 8;
}

.chaptered-video__prev {
  color: $color-white;
  left: 20px;
  position: absolute;
  transform: translateY(-50%);
  width: 80px;

  &:hover {
    color: $color-black;
  }
}

.chaptered-video__next {
  color: $color-white;
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
  width: 80px;

  &:hover {
    color: $color-black;
  }
}

.chaptered-video__options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  min-width: 250px;
}

.chaptered-video__progress {
  flex-grow: 1;
  margin: 0 10px;
  padding: 0 5px;
  position: relative;
}

.chaptered-video__progress-bar {
  cursor: pointer;
  height: 16px;
  padding: 1px 0;
}

.chaptered-video__progress-bar-background {
  background: rgba($color-white, .5);
  height: 4px;
  margin: 6px 0;
  padding: 1px 2px;
}

.chaptered-video__progress-bar-progress {
  background: var(--color-black);
  height: 2px;
  position: absolute;
  top: 8px;
  width: 30%; // arbitrary test value
}

.chaptered-video__up-next {
  background: rgba($color-black, .5);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity .4s ease;
  width: 100%;
  z-index: 5;

  .chaptered-video--video-finished & {
    opacity: 1;
    pointer-events: all;
  }
}

.chaptered-video__up-next-content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chaptered-video__up-next-title {
  color: var(--color-white);
  font-size: 24px;
  margin-bottom: 10px;
  padding-left: 50px;
}

.chaptered-video__up-next-content-inner {
  display: flex;
  flex-direction: column;
  position: relative;
}

.chaptered-video__time {
  color: $color-white;
  min-width: 250px;
}

.chaptered-video__video-bar {
  align-items: center;
  background: transparent;
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 62px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: calc(100% - 250px); // account for scroll down component -- maybe variation material?
  z-index: 2;

  @include mobile-only {
    display: none;
  }
}

.chaptered-video__video-pause {
  background: transparent;
  color: var(--color-white);

  .chaptered-video:not(.chaptered-video--video-playing) & {
    display: none;
  }
}

.chaptered-video__video-play {
  background: transparent;
  color: var(--color-white);

  .chaptered-video--video-playing & {
    display: none;
  }
}

.chaptered-video__up-next-controls {
  list-style: none;
  padding-left: 0;
}

.chaptered-video__up-next-control {
  margin-bottom: 15px;
}

.chaptered-video__up-next-cancel {
  font-size: 18px;
  text-decoration: underline;
}

.chaptered-video__value-chain-card {
  &::before {
    background: none;
  }
}
