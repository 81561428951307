
.scroll-down {
  background: var(--color-white);
  bottom: 0;
  color: var(--color-black);
  display: block;
  min-height: 100px;
  opacity: 1;
  padding: 10px 20px 15px;
  position: absolute;
  right: 0;
  transition: background-color $easing-change, color $easing-change, opacity $easing-change;
  width: 270px;
  z-index: 3;

  &:hover,
  &:focus {
    background-color: var(--color-black);
    color: var(--color-white);
  }

  .scroll-down__title,
  .scroll-down__text {
    pointer-events: none;
  }

  .scroll-down__title {
    @include typography(dig-deeper);

    margin-bottom: 10px;
    white-space: nowrap;

    svg {
      height: var(--heading-4-line-height);
      vertical-align: bottom;
      width: var(--heading-4-line-height);
    }
  }

  .scroll-down__text {
    @include typography(text);

    line-height: 1.1em;
  }
}

.scroll-down--hidden {
  opacity: 0;
  pointer-events: none;
}
