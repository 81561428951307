
@mixin slide-base($is-overlay) {
  position: relative;

  &::before {
    @if $is-overlay {
      background: rgba(0, 0, 0, .5);
    }

    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin slide-item {
  // This is the cause of the subpixel bug of the clip-path
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: $value-chain-slide-width;
}

.value-chain {
  background: rgba($color-black, .5);
  transition: transform .2s ease;

  &:not(.value-chain--overlay) {
    padding: 100px 0;
  }
}

.value-chain--overlay {
  background: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-value-chain-overlay;

  &:not(.value-chain--open) {
    pointer-events: none;
    transform: translateY(100%);
  }
}

.value-chain__closer.link {
  color: var(--color-white);
  left: 50%;
  position: absolute;
  top: 35px;
  transform: translateX(-50%);
  z-index: 5;

  .value-chain:not(.value-chain--overlay) & {
    display: none;
  }
}

.value-chain__content.swiper-container {
  height: 100%;
  position: relative;
}

.value-chain__edge-slide.swiper-slide {
  .value-chain--overlay & {
    @include slide-base(true);
  }

  .value-chain:not(.value-chain--overlay) & {
    @include slide-base(false);
  }

  &:last-child {
    flex-grow: 1;
  }

  width: 50px;
}

.value-chain__home-slide.swiper-slide {
  @include slide-item;

  .value-chain--overlay & {
    @include slide-base(true);
  }

  .value-chain:not(.value-chain--overlay) & {
    @include slide-base(false);
  }

  &::before {
    $half-height: $value-chain-slide-image-height / 2;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 50%, 50px 50%, 50px calc(50% + #{$half-height}), 330px calc(50% + #{$half-height}), 330px calc(50% - #{$half-height}), 50px calc(50% - #{$half-height}), 50px 50%, 0 50%);
  }

  width: $value-chain-slide-width;
}

.value-chain__item.swiper-slide {
  @include slide-item;

  .value-chain--overlay & {
    @include slide-base(true);
  }

  .value-chain:not(.value-chain--overlay) & {
    @include slide-base(false);
  }
}
