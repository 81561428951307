
.video {
  background-color: var(--color-black);
  contain: content;
  display: block;
  position: relative;
  user-select: none;

  .video__video {
    display: block;
    height: 100%;
    transform: translate3d(0, 0, 0); // Edge bug
    transition: filter .2s ease, opacity $easing-change-slower;
    width: 100%;
  }

  .video__hit-target {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video__button {
    color: var(--color-white);
    display: none;
    font-size: 2rem;
    height: 96px;
    left: 50%;
    line-height: 1em;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

.video--loaded {
  .video__video {
    opacity: 1;
  }
}

.video--background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  //~ * {
  //  color: var(--color-white);
  //  position: relative;
  //  z-index: 1;
  //}
}

.video--blurred {
  .video__video {
    //filter: brightness(.66) contrast(1.1);
  }
}

.video--playing {
  transition: filter .3s ease;

  .video__hit-target {
    display: none;
  }
}

.video:not(.video--playing) {
  .video__video {
    //filter: brightness(.66) contrast(1.1);
  }

  .video__button--play {
    display: block;
  }
}
