
.inpage-navigation {
  list-style: none;
  margin: 0;
  padding: 0;

  .inpage-navigation__item {
    margin: .5em 0;
  }
}
