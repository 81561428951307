
.article-photo-carousel {
  contain: content;
  margin: var(--margin) 0;
  position: relative;
}

.article-photo-carousel__grid {
  @include grid-container;
}

.article-photo-carousel__navigation {
  left: 0;
  padding-top: percentage(9 / 16);
  position: absolute;
  top: 0;
  z-index: 10;
}

.article-photo-carousel__wrapper {
  grid-column: var(--layout-photo);
}
