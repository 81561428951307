
:root {
  --link-main-font-family: #{$basis-grotesque-pro-font-stack};
  --link-main-font-size: 28px;
  --link-main-font-style: normal;
  --link-main-font-variant: normal;
  --link-main-font-weight: bold;
  --link-main-letter-spacing: -.03em;
  --link-main-line-height: 36px;
  --link-main-margin-bottom: 0;
  --link-main-margin-top: 0;

  @include portrait {
    --link-main-font-size: 36px;
    --link-main-line-height: 42px;
  }

  @include landscape {
    --link-main-font-size: 48px;
    --link-main-line-height: 60px;
  }
}
