
// Base colors
$color-black: #000;
$color-white: #fff;

// Functional colors
$color-error: #f03;

// Transparencies
$transparency-overlay: rgba($color-black, .5);

// Beziers
$bezier-fancy: cubic-bezier(.26, .88, .57, .9);

// Easings
$easing-change: .15s $bezier-fancy;
$easing-change-slower: .35s $bezier-fancy;
$easing-change-faster: .05s $bezier-fancy;
$easing-change-extraslow: 1s $bezier-fancy;
$easing-linear: .15s linear;

// Layers / z-indexes
$z-index-hide: -1;
$z-index-hit-target: 100;
$z-index-form-field-button: 10;
$z-index-back-button: 130;
$z-index-menu-button: 130;
$z-index-sound-button: 130;
$z-index-logo: 160;
$z-index-menu-bar: 180;
$z-index-intro-overlay: 190;
$z-index-menu-overlay: 200;
$z-index-value-chain-overlay: 210;

// The Value Chain variables
$value-chain-slide-image-width: 280px;
$value-chain-slide-image-height: 490px;
$value-chain-slide-offset-left: 50px;
$value-chain-slide-offset-right: 50px;
$value-chain-slide-width: $value-chain-slide-image-width + $value-chain-slide-offset-left + $value-chain-slide-offset-right;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
:root {
  --color-black: #000;
  --color-white: #fff;
  --color-error-background: #f03;
  --color-error-foreground: #fff;
}

// TODO: Test and turn on at the end
//@media (prefers-color-scheme: dark) {
//  :root {
//    --color-black: #fff;
//    --color-white: #000;
//  }
//}
