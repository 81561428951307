
.card-grid {
  margin: var(--margin) 0;
  padding: var(--component-margin) 0;
  position: relative;

  .card-grid__grid {
    @include grid-container;
  }

  .card-grid__wrapper {
    grid-column: column 2 / span 10;
  }

  .card-grid__content {
    @include grid-container;
  }

  .card-grid__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);

    .card__title {
      @include typography(paragraph-bold);

      margin: 10px 0 0;
    }

    @include notebook {
      grid-template-columns: repeat(3, 1fr);

      .card {
        padding: 15px;
      }
    }
  }

  .card-grid__pagination {
    margin-top: 40px;
    text-align: center;
  }
}
