
:root {
  --heading-label-font-family: #{$basis-grotesque-pro-font-stack};
  --heading-label-font-size: 16px;
  --heading-label-font-style: normal;
  --heading-label-font-variant: normal;
  --heading-label-font-weight: bold;
  --heading-label-letter-spacing: -.4px;
  --heading-label-line-height: 1.2em;
  --heading-label-margin-bottom: .5rem;
  --heading-label-margin-top: 2rem;

  @include portrait {
    --heading-label-font-size: 17px;
  }

  @include landscape {
    --heading-label-font-size: 18px;
  }
}
