
.article-header {
  contain: content;
  padding-top: 120px;
  position: relative;

  @include landscape {
    padding-top: var(--margin);
  }

  &.article-header--main {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .article-header__grid {
    @include grid-container;
  }

  .article-header__wrapper {
    grid-column: var(--layout-content);
  }

  .article-header__inpage-navigation {
    grid-column: var(--layout-aside);
    padding: .3em 0;

    .link {
      display: block;
    }
  }

  .article-header__title {
    @include typography(heading-1);

    margin-bottom: var(--heading-1-margin-bottom);
  }

  .article-header__pre-title {
    @include typography(heading-4);
  }

  .article-header__header,
  .article-header__introduction {
    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .article-header__introduction {
    //
  }
}
