
.logo {
  color: inherit;
  contain: content;
  display: inline-block;
  line-height: 0;
  position: relative;
  transition: color $easing-change;
  user-select: none;
  vertical-align: top;
  width: 142px;

  .logo__gif {
    position: relative;
    top: -9px;

    @include notebook {
      top: -15px;
    }
  }

  .logo__svg {
    display: block;
    height: 100%;
    width: 100%;

    path {
      fill: currentColor;
      transition: fill $easing-change;
    }
  }
}
