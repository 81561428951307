
:root {
  --button-font-family: #{$basis-grotesque-pro-font-stack};
  --button-font-size: inherit;
  --button-font-style: normal;
  --button-font-variant: normal;
  --button-font-weight: bold;
  --button-letter-spacing: -.03em;
  --button-line-height: inherit;
  --button-margin-bottom: 0;
  --button-margin-top: 0;

  @include portrait {
    //
  }

  @include landscape {
    //
  }
}
