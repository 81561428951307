
.picture {
  background-color: var(--color-black);
  contain: content;
  display: block;
  position: relative;
  user-select: none;

  .picture__image {
    height: 100%;
    opacity: 0;
    transform: translate3d(0, 0, 0); // Edge bug
    transition: opacity $easing-change-slower;
    width: 100%;
  }
}

.picture--loaded {
  .picture__image {
    opacity: 1;
  }
}

.picture--background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  .picture__image {
    filter: brightness(.66) contrast(1.1);
  }

  ~ * {
    color: var(--color-white);
    position: relative;
    z-index: 1;
  }
}
