
:root {
  --link-big-font-family: #{$basis-grotesque-pro-font-stack};
  --link-big-font-size: 20px;
  --link-big-font-style: normal;
  --link-big-font-variant: normal;
  --link-big-font-weight: normal;
  --link-big-letter-spacing: -.03em;
  --link-big-line-height: 20px;
  --link-big-margin-bottom: 0;
  --link-big-margin-top: 0;

  @include portrait {
    --link-big-font-size: 24px;
    --link-big-font-weight: medium;
    --link-big-line-height: 22px;
  }

  @include landscape {
    --link-big-font-size: 24px;
    --link-big-line-height: 22px;
  }
}
