$offset-left: $value-chain-slide-offset-left;
$offset-right: $value-chain-slide-offset-right;
$line-offset-left: 110px;
$line-offset-top: 40px;
$picture-height: $value-chain-slide-image-height;
$picture-width: $value-chain-slide-image-width;
$content-width: $picture-width + 40px;
$text-offset-top: 130px;
$text-hover-offset-top: 108px;

.value-chain-card {
  height: $picture-height;
  position: relative;
  width: #{$picture-width + $offset-left + $offset-right};

  &::before {
    background: var(--color-white);
    content: '';
    display: block;
    height: 2px;
    left: $line-offset-left;
    position: absolute;
    top: $text-offset-top + $line-offset-top;
    width: calc(100% - #{$line-offset-left});
    z-index: 3;

    .swiper-slide:nth-last-child(2) & {
      display: none;
    }
  }

  &.value-chain-card--local {
    &::before {
      display: none;
    }
  }
}

.value-chain-card__content {
  color: var(--color-white);
  left: 0;
  padding-left: 15px;
  position: absolute;
  top: $text-offset-top;
  width: $content-width;
  z-index: 8;

  @include hover('.value-chain-card__hit-target', '&') {
    @include portrait {
      top: $text-hover-offset-top;
    }
  }

  &::before {
    content: '\00a0 ';
    display: block;
    font-size: 25px;

    // Maybe the not is a bit much -- it can always be converted to an overwrite!
    @include hover('.value-chain-card:not(.value-chain-card--local) .value-chain-card__hit-target', '&') {
      @include portrait {
        display: none;
      }
    }
  }

  &.value-chain-card__content-video {
    left: -80px;

    @include mobile-only {
      left: 0;
    }
  }
}

.value-chain-card__hit-target {
  display: block;
  height: $picture-height;
}

.value-chain-card__icon {
  left: 50%;
  position: absolute;
  top: 15px;
  transform: translateX(-50%);
  z-index: 5;
}

.value-chain-card__label {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 25px;

  @include mobile-only {
    font-size: 20px;
    margin-bottom: 10px;
  }

  @include hover('.value-chain-card__hit-target', '&') {
    @include portrait {
      font-size: 24px;
      margin-bottom: 5px;
      transition: font-size .3s ease;
    }
  }
}

.value-chain-card__picture {
  height: $picture-height;
  margin-left: $offset-left;
  position: relative;
  width: $picture-width;
  z-index: 4;

  @include portrait {
    z-index: 2;
  }

  &::after {
    background: rgba(0, 0, 0, .35);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity .2s ease;
    width: 100%;
  }

  @include hover('.value-chain-card__hit-target', '&') {
    @include portrait {
      z-index: 4; // over white line

      &::after {
        opacity: 0;
      }
    }
  }

  .value-chain-card--local & {
    background: rgba(0, 0, 0, .25);
  }

  .value-chain-card__video + & {
    @include portrait {
      display: none;
    }
  }
}

.value-chain-card__text {
  font-weight: bold;
  opacity: 0;
  transform: translateY(15px);

  @include mobile-only {
    opacity: 1;
  }

  @include hover('.value-chain-card__hit-target', '&') {
    @include portrait {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .3s ease .2s, transform .3s ease .2s;
    }
  }
}

.value-chain-card__text-link {
  margin-top: 50px;
  text-decoration: underline;
}

.chaptered-video__value-chain-card {
  .value-chain-card__label {
    font-size: 24px;

    @include mobile-only {
      font-size: 20px;
    }
  }

  .value-chain-card__title {
    font-size: 64px;
    line-height: 60px;

    @include mobile-only {
      font-size: 42px;
      line-height: 46px;
    }
  }

  .value-chain-card__text {
    display: none;
  }

  .value-chain-card__content {
    top: $text-hover-offset-top;
  }
}

.value-chain-card__title {
  font-size: 24px;
  margin-bottom: 25px;

  @include mobile-only {
    font-size: 42px;
    line-height: 46px;
    margin-bottom: 10px;
  }

  @include hover('.value-chain-card__hit-target', '&') {
    @include portrait {
      font-size: 64px;
      line-height: 60px;
    }
  }
}

.value-chain-card__video {
  margin-left: $offset-left;
  pointer-events: none;
  width: $picture-width;

  @include mobile-only {
    display: none;
  }

  @include hover('.value-chain-card__hit-target', '&') {
    z-index: 4; // over white line
  }

  .video__video {
    opacity: 1;
  }

  .video__button--play {
    display: none;
  }
}
