
.micrio-minimap {
  align-items: center;
  bottom: 0;
  color: var(--color-white);
  display: flex;
  left: 0;
  position: absolute;
  z-index: 5;

  //@include mobile-only {
  //  display: none;
  //}

  @include portrait {
    bottom: 20px;
    left: 40px;
  }
}

.micrio-minimap__close-overview {
  .micrio:not(.micrio--on-overview) & {
    display: none;
  }

  bottom: 10px;
  position: absolute;
  right: 50px;
  transform: translateX(100%);
}

.micrio-minimap__content {
  position: relative;
}

.micrio-minimap__images {
  list-style: none;
}

.micrio-minimap__map {
  height: 151px;
  width: 156px;
}

.micrio-minimap__open-overview {
  .micrio--on-overview & {
    display: none;
  }

  bottom: 10px;
  position: absolute;
  right: 50px;
  transform: translateX(100%);
}

.micrio-minimap-marker {
  fill: transparent;
}

.micrio-minimap-marker--active {
  fill: var(--color-white);
}

.micrio-minimap--only-link {
  .micrio-minimap__open-overview {
    right: auto;
    transform: none;
  }
}
