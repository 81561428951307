
.website-micrio {
  height: 100vh;
  left: 0;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  .micrio-markers .micrio-marker.behind {
    display: none;
  }

  .micrio-controls .micrio-button.zoom-in { display: none; }
  .micrio-controls .micrio-button.zoom-out { display: none; }
  .micrio-controls .micrio-button.close { display: none; }
  .micrio-controls .micrio-button.fullscreen { display: none; }
  .micrio-controls .micrio-button.maximize { display: none; }
  .micrio-controls .micrio-button.volume-up { display: none; }

  .micrio button.micrio-marker {
    font-size: 18px;
    margin-top: 10px;
  }

  .micrio-container .marker-container .marker-title {
    font-size: 18px;
    opacity: 1 !important;
    top: 60px;
    transform: translate(-50%, 25px) !important;

    @include mobile-only {
      top: 30px;
    }
  }

  .micrio-container .marker-container .marker {
    background: $color-white;
    background-size: cover;
    border: 4px solid $color-white;
    border-radius: 50%;
    box-shadow: none;
    height: 126px;
    position: relative;
    top: -10px;
    width: 126px;

    @include mobile-only {
      height: 60px;
      width: 60px;
    }

    &::after {
      background: transparent;
      border: 15px solid $color-white;
      border-bottom: 15px solid transparent;
      border-left-color: transparent;
      border-left-width: 9px;
      border-right-color: transparent;
      border-right-width: 9px;
      border-top: 15px solid $color-white;
      bottom: -30px;
      content: '';
      display: block;
      height: 15px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 10px;
    }
  }

  .marker-container.class-default:hover .marker {
    background-color: transparent;
    box-shadow: none;
  }

  .micrio-minimap {
    @include mobile-only {
      left: 10px;
    }
  }
}
