
.button {
  @include typography(button);

  contain: content;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 520px);
  overflow: hidden;
  padding: 6px 30px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: color $easing-change, background-color $easing-change, border-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;

  .button__span {
    display: block;
    flex: 1 1 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .button__icon {
    display: block;
    flex: 0 0 var(--button-line-height);
    height: var(--button-line-height);
    padding: .1em;
    width: var(--button-line-height);

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  &:disabled,
  .button--disabled {
    cursor: default;
    opacity: .4;
    pointer-events: none;
  }
}

.button--primary {
  background-color: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);

  &:not(.button--disabled):not(:disabled) {
    &:hover,
    &:focus,
    &.button--selected {
      background-color: var(--color-white);
      border-color: var(--color-white);
      color: var(--color-black);
    }
  }
}
