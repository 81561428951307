
:root {
  --heading-5-font-family: #{$basis-grotesque-pro-font-stack};
  --heading-5-font-size: 10px;
  --heading-5-font-style: normal;
  --heading-5-font-variant: normal;
  --heading-5-font-weight: bold;
  --heading-5-letter-spacing: 0;
  --heading-5-line-height: 1.2em;
  --heading-5-margin-bottom: .5rem;
  --heading-5-margin-top: 2rem;

  @include portrait {
    --heading-5-font-size: 22px;
  }

  @include landscape {
    --heading-5-font-size: 24px;
  }
}
