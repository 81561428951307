.sound-button {
  display: inline-block;
  z-index: $z-index-sound-button;

  .sound-button__on {
    display: inline-flex;
  }

  .sound-button__off {
    display: none;
  }
}

.sound-button__link.icon-button {
  background-color: transparent;
  height: 100%;
  padding: 0;
  width: 100%;

  .icon-button__icon {
    height: 100%;
    width: 100%;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    outline: 0;
  }
}

.sound-button__muted {
  .sound-button__off {
    display: inline-flex;
  }

  .sound-button__on {
    display: none;
  }
}
