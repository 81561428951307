
.scroll-down-struct {
  background: var(--color-white);
  color: var(--color-black);
  position: absolute;
  right: 0;
  top: 100vh;
  transform: translateY(-100%);
  width: 250px;
  z-index: 10;
}
