@import '../../../assets/stylesheets/utilities/mixins/fix-antialiasing';

html {
  @include fix-antialiasing; // Prevent smudgy bold fonts on OSX
  @include font-basis-grotesque-pro;
  @include typography(paragraph);

  background-color: var(--color-white);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
  scroll-behavior: smooth;

  em,
  i {
    font-style: oblique;
  }

  strong,
  b {
    font-weight: var(--font-weight-semibold);
  }

  &.prevent-scrolling {
    max-height: 100vh;
    overflow: hidden;
  }

  &.is-edge {
    display: block; // Otherwise, developer tools will crash in Edge - so no sticky footer
  }
}

body {
  background-color: var(--color-white);
  color: var(--color-black);
  //contain: content;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  font: inherit;
  -webkit-font-smoothing: antialiased; // Prevent smudgy bold fonts on OSX
  margin: 0 auto;
  // max-width: var(--document-max-width);
  min-width: var(--document-min-width);
  position: relative;
  width: 100%;
}
