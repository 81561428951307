
:root {
  --link-big-bold-font-family: #{$basis-grotesque-pro-font-stack};
  --link-big-bold-font-size: 20px;
  --link-big-bold-font-style: normal;
  --link-big-bold-font-variant: normal;
  --link-big-bold-font-weight: regular;
  --link-big-bold-letter-spacing: -.03em;
  --link-big-bold-line-height: 20px;
  --link-big-bold-margin-bottom: 0;
  --link-big-bold-margin-top: 0;

  @include portrait {
    --link-big-bold-font-size: 22px;
    --link-big-bold-line-height: 20px;
  }

  @include landscape {
    --link-big-bold-font-size: 24px;
    --link-big-bold-line-height: 22px;
  }
}
