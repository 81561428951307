@import '../../../assets/stylesheets/utilities/mixins/clearfix';

.main {
  @include clearfix;

  background: var(--color-white);
  contain: content;
  flex-grow: 1;
  position: relative;
  z-index: 2;
}
