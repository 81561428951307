
.value-chain-opener {
  bottom: 0;
  color: var(--color-white);
  display: block;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  z-index: 10;

  @include mobile-only {
    bottom: 30px;
  }

  @include portrait {
    height: 60px;
  }
}

.value-chain-opener--value-chain-open {
  display: none;
}
