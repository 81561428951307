
:root {
  --heading-1-font-family: #{$basis-grotesque-pro-font-stack};
  --heading-1-font-size: 42px;
  --heading-1-font-style: normal;
  --heading-1-font-variant: normal;
  --heading-1-font-weight: bold;
  --heading-1-letter-spacing: -.03em;
  --heading-1-line-height: 1.2em;
  --heading-1-margin-bottom: 1rem;
  --heading-1-margin-top: 2rem;

  @include portrait {
    --heading-1-font-size: 63px;
  }

  @include landscape {
    --heading-1-font-size: 84px;
  }
}
