
:root {
  --heading-intro-font-family: #{$basis-grotesque-pro-font-stack};
  --heading-intro-font-size: 46px;
  --heading-intro-font-style: normal;
  --heading-intro-font-variant: normal;
  --heading-intro-font-weight: bold;
  --heading-intro-letter-spacing: -1.3px;
  --heading-intro-line-height: 1em;
  --heading-intro-margin-bottom: .5rem;
  --heading-intro-margin-top: 2rem;

  @include portrait {
    --heading-intro-font-size: 46px;
  }

  @include landscape {
    --heading-intro-font-size: 64px;
    --heading-intro-letter-spacing: -1.8px;
  }
}
