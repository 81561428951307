
:root {
  --introduction-font-family: #{$basis-grotesque-pro-font-stack};
  --introduction-font-size: 24px;
  --introduction-font-style: normal;
  --introduction-font-variant: normal;
  --introduction-font-weight: bold;
  --introduction-letter-spacing: -.03em;
  --introduction-line-height: 1.3em;
  --introduction-margin-bottom: .5em;
  --introduction-margin-top: 1em;

  @include portrait {
    //--introduction-font-size: 38px;
  }

  @include landscape {
    //--introduction-font-size: 32px;
  }
}
